import React, { Component } from "react";
import Hamburguer from "./menu/Hamburguer";
import { translate } from "react-translate";
import { UISref } from "@uirouter/react";
import Menu from "./menu/Menu";
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open:
				["/es", "/en", "/"].indexOf(window.location.pathname) !== -1
					? false
					: true
		};
	}

	_toogle(routeName) {
		let { lang, router } = this.props;

		this.setState({ open: !this.state.open });

		if (
			window.innerWidth >= 1024 &&
			routeName !== "layout.homelang" &&
			routeName !== "close"
		)
			this.setState({ open: true });
		if (routeName === "close" && window.innerWidth >= 1024) {
			let { router } = this.props.transition;
			router.stateService.go("layout.homelang", { lang });
		} else if (routeName === "close-button") {
			let { router } = this.props.transition;
			this.setState({ open: true });
			router.stateService.go("layout.homelang", { lang });
		}
		// this.setState({ open : !this.state.open }, () => {
		// 	let { router } = this.props.transition;
		// 	router.stateService.go(routeName || 'layout.homelang',{ lang })
		// 	if(routeName === 'layout.homelang')
		// 		this.setState({ open : false })
		// 	else
		// 		this.setState({ open : true })

		// });
	}

	render() {
		let { t, lang, routeName } = this.props;
		return (
			<div>
				<div className="header">
					<Hamburguer
						open={this.state.open}
						onChange={() => this._toogle("close")}
					/>
					<img
						alt="Logo last straw"
						className="logo"
						src="/images/logo-last-straw.png"
					/>
					<a
						href={`/${lang === "es" ? "en" : "es"}`}
						className="switch"
					>
						{t("lang")}
					</a>
				</div>
				<Menu
					onClose={() => this._toogle("close-button")}
					toogle={this._toogle.bind(this)}
					lang={lang}
					visible={this.state.open}
					size="full"
				/>
			</div>
		);
	}
}
export default translate("header")(Header);
