import React, { Component } from "react";
import classNames from "classnames";
import { translate } from "react-translate";
class Hamburguer extends Component {
	render() {
		let { t } = this.props;
		let container = classNames("hamburger hamburger--elastic", {
			"is-active": this.props.open
		});
		return (
			<button className="wrapper-menu" onClick={this.props.onChange}>
				<div className={container}>
					<div className="hamburger-box">
						<div className="hamburger-inner" />
					</div>
				</div>
				<span>{t(this.props.open ? "open" : "close")}</span>
			</button>
		);
	}
}

export default translate("header")(Hamburguer);
