import React, { Component } from "react";
import classNames from "classnames";
class Bullet extends Component {
	_onClick() {
		if (this.props.onClick) this.props.onClick();
	}
	render() {
		let container = classNames("bullet", {
			active: this.props.isActive
		});
		return (
			<button className={container} onClick={this._onClick.bind(this)} />
		);
	}
}

export default Bullet;
