import React, { Component } from "react";
import Modal from "react-modal";

class ModalVideo extends Component {
	_onClose() {
		if (this.props.onClose) this.props.onClose();
	}

	render() {
		return (
			<Modal
				isOpen={this.props.visible}
				onRequestClose={this._onClose.bind(this)}
				contentLabel=""
			>
				<div>
					<button
						class="closebutton"
						onClick={this._onClose.bind(this)}
					>
						<i class="fas fa-times" />
					</button>
					<iframe
						class="vimeo"
						src="https://player.vimeo.com/video/329238031"
						width="640"
						height="360"
						frameborder="0"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					/>
				</div>
			</Modal>
		);
	}
}

export default ModalVideo;
