import React, { Component } from "react";
import { UIView } from "@uirouter/react";
import { TranslatorProvider } from "react-translate";

class Layout extends Component {
	render() {
		let { $stateParams } = this.props;
		let lang = $stateParams.lang || "es";
		return (
			<TranslatorProvider translations={require(`../i18n/${lang}.json`)}>
				<div className="wrapper">
					<UIView
						render={(Component, props) => (
							<Component {...props} lang={lang} />
						)}
					/>
				</div>
			</TranslatorProvider>
		);
	}
}

export default Layout;
