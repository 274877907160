import React, { Component } from "react";
import ButtonHexagonal from "../components/ButtonHexagonal";
import { translate } from "react-translate";
import Carousel from "nuka-carousel";
import Bullet from "../components/Bullet";
import Href from "../components/Href";
import ThreeSixty from "threesixty";
import ModalVideo from "../components/ModalVideo";
import moment from "moment";
import { UISref } from "@uirouter/react";
/*
<Carousel
	renderCenterLeftControls={null}
	renderCenterRightControls={this._controls.bind(this)}
	renderBottomCenterControls={null}
	vertical={true}
>
	<div>
		<p className="text-center" dangerouslySetInnerHTML={{ __html: t('paragraph') }}></p>
		<p className="text-center" dangerouslySetInnerHTML={{ __html: t('paragraph2') }}></p>
		<p className="text-center" dangerouslySetInnerHTML={{ __html: t('paragraph3') }}></p>
	</div>
</Carousel>
*/

class Index extends Component {
	constructor(props) {
		super(props);

		let minutes = 0;
		let date = window.localStorage.getItem("viewVideo");
		let showModal = false;
		if (date && moment(date).isValid()) {
			minutes = moment().diff(date, "minutes");
		} else {
			showModal = true;
		}

		this.state = {
			slideIndex : 0,
			index: 0,
			showModal: showModal == true || minutes > 40 ? true : false
		};
	}
	_controls({ goToSlide, slideCount, currentSlide }) {
		let components = [];
		for (let i = 0; i < slideCount; i++)
			components.push(
				<Bullet
					key={`bullet-${i}`}
					isActive={currentSlide === i}
					onClick={() => goToSlide(i)}
				/>
			);
		return components.map(item => item);
	}

	componentDidMount() {
		let images = [];
		for (var i = 86; i >= 0; i--) images.push(`/images/render/panal_${i}.png`);
		var threesixty = new ThreeSixty(this.refs.images, images,{
			dragTolerance : 3,
			swipeTolerance : 3
		});
		threesixty.init();

		let imagesTwo = [];
		for (var i = 86; i >= 0; i--)
			imagesTwo.push(`/images/render/col_${i}.png`);
		var threesixty2 = new ThreeSixty(this.refs.images2, imagesTwo);
		threesixty2.init();

		setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 300);


		// document.addEventListener("touchmove", (e) => {
		// 	e.preventDefault(); 
		// }, {passive: false});
	}


	donwload(){
		window.open('/PLANOS_LAST_STRAW.pdf', "_new");
	}
	
	componentWillMount() {
		document.body.className = "home";
		if (this.state.showModal)
			window.localStorage.setItem("viewVideo", new Date());
	}

	render() {
		let { t, lang } = this.props;
		return (
			<div className="container">
				<div className="column sidebar">
					<div className="column-content home-scroll">
						<img
							width={270}
							alt="Logo last straw"
							className="logo img-center"
							src="/images/logo-last-straw.png"
						/>

						<Carousel
							transitionMode="scroll"
							dragging={false}
							swiping={false}
							disableAnimation={true}
							heightMode="max"
							renderCenterLeftControls={null}
							renderCenterRightControls={this._controls.bind(
								this
							)}
							renderBottomCenterControls={null}
							vertical={false}
							slideIndex={this.state.slideIndex}
							afterSlide={(slideIndex) => {
									let query = {
										slideIndex,

									}
									if(slideIndex !== 0)
										query.index = slideIndex - 1;
									this.setState(query)
								}}
						>
							<div>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("paragraph")
									}}
								/>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("paragraph2")
									}}
								/>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("paragraph3")
									}}
								/>
							</div>
							<div>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("colmena1")
									}}
								/>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("colmena2")
									}}
								/>
							</div>
							<div>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("panal1")
									}}
								/>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("panal2")
									}}
								/>
								<p
									className="text-center"
									dangerouslySetInnerHTML={{
										__html: t("panal3")
									}}
								/>
							</div>
						</Carousel>
						<div
							className="row"
							style={{ marginBottom: "30px", minHeight: 142 }}
						>
							<Href routeName={"layout.sumate"} params={{ lang }}>
								<ButtonHexagonal name="add" />
							</Href>

							<ButtonHexagonal name="donwload"  onClick={this.donwload.bind(this)} />
						</div>
						
							<a
								href={ lang === 'es' ? "/avisodeprivacidad" : '/en/avisodeprivacidad'}
								target="new"
								style={{ marginBottom: 30, color: "#575757" }}
								className="privacy text-center"
							>
								{t("aviso")}
							</a>
						
						<div className="logos">
							<a href="http://www.sondemiel.com/" target="_new">
								<img className="color" src="/images/sondemiel.svg" />
							</a>

							<a href="https://www.prxlab.com/" target="_new">
								<img className="color" src="/images/prxlab.svg" />
							</a>

							<a href="http://www.arbol.mx/" target="_new">
								<img className="color" src="/images/arbol.svg" />
							</a>
							<a href="https://flock.mx/contacto/" target="_new">
								<img className="color" src="/images/flock.svg" />
							</a>
						</div>
					</div>
				</div>
				<div className="column center gradient" id="wrapper">
					<Carousel
						dragging={false}
						swiping={false}
						disableAnimation={true}
						heightMode="max"
						slideIndex={this.state.index}
						className="carrousel"
						renderCenterLeftControls={null}
						renderCenterRightControls={null}
						renderBottomCenterControls={null}
						withoutControls={true}
						afterSlide={(index) => {
								let query = {
									index,
								}
								
								query.slideIndex = index + 1;
								this.setState(query)
							}}
					>
						<div
							className="image-carrousel colmena"
							id="images2"
							ref="images2"
							
						>
							<img src="/images/render/col_86.png" draggable="false" />
						</div>
						<div
							
							className="image-carrousel"
							id="images"
							ref="images"
						>
							<img src="/images/render/panal_0.png" draggable="false" />
						</div>
					</Carousel>
					<div className="bullets-group">
						<Bullet
							isActive={0 === this.state.index}
							onClick={() => this.setState({ index: 0 })}
						/>
						<Bullet
							isActive={1 === this.state.index}
							onClick={() => this.setState({ index: 1 })}
						/>
					</div>
				</div>
				<ModalVideo
					visible={this.state.showModal}
					onClose={() =>
						this.setState({ showModal: !this.state.showModal })
					}
				/>
			</div>
		);
	}
}

export default translate("home")(Index);
