import React, { Component } from "react";
import { translate } from "react-translate";
class TextArea extends Component {
	_handleChange(event) {
		if (this.props.onChange) this.props.onChange(event.target.value);
	}

	render() {
		let { t, name, type, value } = this.props;
		return (
			<div className="form-group">
				<label>{t(name)}</label>
				<textarea
					onChange={this._handleChange.bind(this)}
					value={value}
				/>
			</div>
		);
	}
}

export default translate("form")(TextArea);
