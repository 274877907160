import React, { Component } from "react";
import { translate } from "react-translate";
import ButtonHexagonal from "../components/ButtonHexagonal";
import Input from "../components/form/Input";
import TextArea from "../components/form/TextArea";
import axios from "axios";
import { validEmail } from "../config/Utils";
import ModalThanks from "../components/ModalThanks";

class Sumate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: 0,
			name: "",
			phone: "",
			email: "",
			comments: "",
			loading: false,
			visible: false
		};
	}
	componentWillMount() {
		document.body.className = "join-us";
	}

	_toogle(active) {
		if (active === this.state.active) this.setState({ active: 0 });
		else this.setState({ active });
	}

	_share(field) {
		let { lang } = this.props;
		window.FB.ui(
			{
				method: "feed",
				link: `https://laststraw.lat/${lang}/${field}`
			},
			response => {}
		);
	}

	_send() {
		this.setState({ loading: true });
		let { name, phone, email, comments } = this.state;
		const formData = new FormData();
		formData.append("FORM", "soy apicultor");
		formData.append("NAME", name);
		formData.append("PHONE", phone);
		formData.append("EMAIL", email);
		formData.append("MESSAGE", comments);
		formData.append("LANG", this.props.lang);
		axios
			.post("http://laststraw.flockos.com/email/index.php", formData, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				}
			})
			.then(response => {
				this.setState({
					email: "",
					name: "",
					phone: "",
					comments: "",
					loading: false,
					visible: true
				});
			})
			.catch(err => {
				this.setState({ loading: false });
			});
	}

	_getDisabled() {
		let disabled = false;
		if (!validEmail(this.state.email)) disabled = true;
		if (!this.state.name || this.state.name.length <= 3) disabled = true;
		if (!this.state.phone || this.state.phone.length < 8) disabled = true;
		if (!this.state.comments || this.state.comments.length <= 5)
			disabled = true;
		return disabled;
	}

	render() {
		let { lang, t } = this.props;
		let { active } = this.state;
		return (
			<div className="container">
				<div className="column" />
				<div className="column">
					<h1 className="text-center">{t("title")}</h1>

					<div className="row-column">
						<div className="column-item">
							<ButtonHexagonal
								size="big"
								onClick={() => this._toogle(1)}
								name="soy-restaurantero"
							/>
							{active === 1 ? (
								<div className="item">
									<p
										dangerouslySetInnerHTML={{
											__html: t("soy-restaurantero")
										}}
										className="text-center"
									/>
									<button
										onClick={() =>
											this._share("soy-restaurantero")
										}
										className="facebook"
									>
										<img
											src="/images/facebook-logo.svg"
											width={20}
										/>
										{t("share")}
									</button>
								</div>
							) : null}
						</div>
						<div className="column-item">
							<ButtonHexagonal
								size="big"
								onClick={() => this._toogle(2)}
								name="consumidor"
							/>
							{active === 2 ? (
								<div className="item">
									<p
										dangerouslySetInnerHTML={{
											__html: t("consumidor")
										}}
										className="text-center"
									/>
									<button
										onClick={() =>
											this._share("soy-consumidor")
										}
										className="facebook"
									>
										<img
											src="/images/facebook-logo.svg"
											width={20}
										/>
										{t("share")}
									</button>
								</div>
							) : null}
						</div>
						<div className="column-item">
							<ButtonHexagonal
								size="big"
								onClick={() => this._toogle(3)}
								name="apicultor"
							/>
							{active === 3 ? (
								<div className="item">
									<p
										dangerouslySetInnerHTML={{
											__html: t("apicultor")
										}}
										className="text-center"
									/>
									<p style={{ width: "100%" }}>
										<b>{t("form")}</b>
									</p>
									<Input
										value={this.state.name}
										onChange={name =>
											this.setState({ name })
										}
										name="name"
									/>
									<Input
										maxLength={12}
										value={this.state.phone}
										onChange={phone =>
											this.setState({
												phone: phone.replace(
													/([a-zA-Z])+/gi,
													""
												)
											})
										}
										type="tel"
										name="phone"
									/>
									<Input
										value={this.state.email}
										onChange={email =>
											this.setState({ email })
										}
										type="email"
										name="email"
									/>
									<TextArea
										value={this.state.comments}
										onChange={comments =>
											this.setState({ comments })
										}
										type="comments"
										name="comments"
									/>
									<button
										disabled={this._getDisabled()}
										className="send medium"
										onClick={this._send.bind(this)}
									>
										{t(
											this.state.loading
												? "loading"
												: "send"
										)}
									</button>
								</div>
							) : null}
						</div>
					</div>
				</div>
				<ModalThanks
					visible={this.state.visible}
					onClose={() =>
						this.setState({ visible: !this.state.visible })
					}
				/>
			</div>
		);
	}
}

export default translate("sumate")(Sumate);
