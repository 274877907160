import React, { Component } from "react";

class Card extends Component {
	render() {
		let { data } = this.props;
		return (
			<a href={data.href} target="_new">
				<div className="card">
					<div className="card-header" style={{ 'background-image' : `url(${data.image})` }}>
					</div>
					<div className="card-body">
						<h2>{data.title}</h2>
						<span>{data.subtitle}</span>
					</div>
				</div>
			</a>
		);
	}
}

export default Card;
