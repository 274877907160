import React, { Component } from "react";
import { translate } from "react-translate";
import { UISref, UISrefActive } from "@uirouter/react";
class MenuItem extends Component {
	_onClose() {
		if (this.props.onClose) this.props.onClose();
	}
	render() {
		let { t, name, routeName, params } = this.props;
		return (
			<UISrefActive class="active">
				<div className="menu-item">
					<UISrefActive class="active">
						<UISref to={routeName} params={params}>
							<a onClick={() => this.props.onClick(routeName)}>
								{t(name)}
							</a>
						</UISref>
					</UISrefActive>
					<div className="menu-item-close">
						<button onClick={this._onClose.bind(this)}>X</button>
					</div>
				</div>
			</UISrefActive>
		);
	}
}

export default translate("menu")(MenuItem);
