import React, { Component } from "react";
import Modal from "react-modal";
import { translate } from "react-translate";

class ModalThanks extends Component {
	_onClose() {
		if (this.props.onClose) this.props.onClose();
	}

	render() {
		let { t } = this.props;
		return (
			<Modal
				isOpen={this.props.visible}
				onRequestClose={this._onClose.bind(this)}
				contentLabel=""
			>
				<div class="modal-thanks">
					<button onClick={this._onClose.bind(this)}>
						<i class="fas fa-times" />
					</button>
					<h2>{t("thanks")}</h2>
					<p>{t("thankssub")}</p>
				</div>
			</Modal>
		);
	}
}

export default translate("modal")(ModalThanks);
