import React, { Component } from "react";
import { translate } from "react-translate";
import Card from "../components/Card";

const data = [
	{
		title : "Could Recycled Straws Protect Endangered Bees? ",
		subtitle : "Little Black Book",
		href : "https://lbbonline.com/news/could-recycled-straws-protect-endangered-bees/",
		image : "/images/1_Little_Black_Book_.png" 
	},
	{
		title : "Isobar launches Last Straw to protect bees and reduce plastic pollution",
		subtitle : "The Droom",
		href : "https://www.thedrum.com/news/2019/04/16/isobar-launches-last-straw-protect-bees-and-reduce-plastic-pollution",
		image : "/images/2_The_Droom.png" 
	},
	{
		title : "Apoyan a abejas con panales de popotes",
		subtitle : "Reforma",
		href : "https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?id=1657955&urlredirect=https://www.reforma.com/aplicaciones/articulo/default.aspx?id=1657955",
		image : "/images/3_Reforma.png" 
	},
	{
		title : "Convertir popotes usados en colmenas para la preservación de las abejas",
		subtitle : "Selecciones",
		href : "https://selecciones.com.mx/convertir-popotes-usados-en-colmenas-para-la-preservacion-de-las-abejas/",
		image : "/images/4_Selecciones.png" 
	}
]
class Prensa extends Component {
	componentWillMount() {
		document.body.className = "press";
	}

	render() {
		let { lang, t } = this.props;
		return (
			<div className="container">
				<div className="column" />
				<div className="column">
					<h1 className="text-center">{t("title")}</h1>
					<p
						className="text-center description"
						dangerouslySetInnerHTML={{ __html: t("description") }}
					/>

					<div className="row cards">
						{ data.map( (item,index) => <Card data={item} key={index}/> )}
					</div>
				</div>
			</div>
		);
	}
}

export default translate("prensa")(Prensa);
