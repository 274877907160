import React, { Component } from "react";
import { UIRouter, UIView, pushStateLocationPlugin } from "@uirouter/react";

import Layout from "./screens/Layout";
import LayoutOpenMenu from "./screens/LayoutOpenMenu";
import Index from "./screens/Index";
import Sumate from "./screens/Sumate";
import Donwload from "./screens/Donwload";
import Prensa from "./screens/Prensa";
import Colaboradores from "./screens/Colaboradores";
import Contact from "./screens/Contact";
import Aviso from "./screens/Aviso";
import AvisoEn from './screens/AvisoEn';

const states = [
	{
		name: "LayoutOpenMenu",
		component: LayoutOpenMenu
	},
	{
		name: "layout",
		component: Layout
	},
	{
		name: "layout.home",
		url: "/",
		component: Index
	},
	{
		name: "layout.homelang",
		url: "/:lang",
		component: Index
	},
	{
		name: "layout.sumate",
		url: "/:lang/sumate",
		component: Sumate
	},
	{
		name: "layout.donwload",
		component: Donwload,
		url: "/:lang/descargar"
	},
	{
		name: "layout.prensa",
		component: Prensa,
		url: "/:lang/prensa"
	},
	{
		name: "layout.colaboradores",
		component: Colaboradores,
		url: "/:lang/colaboradores"
	},
	{
		name: "layout.contact",
		component: Contact,
		url: "/:lang/contacto"
	},
	{
		name: "aviso",
		component: Aviso,
		url: "/avisodeprivacidad"
	},
	{
		name : "avisoen",
		component : AvisoEn,
		url : '/en/avisodeprivacidad'
	}
];

const plugins = [pushStateLocationPlugin];

class App extends Component {

	_changeRoute(trans, state) {
		window.scrollTo(0, 0);
		// if(window.ga){
		// 	window.ga('send', {
		// 		hitType: 'pageview',
		// 		page: window.location.pathname
		// 	});
		// 	// window.ga('set', 'page', document.location.pathname);
		// 	// window.ga('send', 'pageview');
		// }
	}

	render() {
		return (
			<UIRouter
				plugins={plugins}
				states={states}
				config={({ transitionService }) => 
					transitionService.onFinish({}, this._changeRoute.bind(this))
				}
			>
				<UIView />
			</UIRouter>
		);
	}
}

export default App;
