import React, { Component } from "react";
import { translate } from "react-translate";
class Input extends Component {
	_handleChange(event) {
		if (this.props.onChange) this.props.onChange(event.target.value);
	}

	render() {
		let { t, name, type, value } = this.props;
		return (
			<div className="form-group">
				<label>{t(name)}</label>
				<input
					maxLength={this.props.maxLength}
					onChange={this._handleChange.bind(this)}
					value={value}
					type={type || "tetx"}
				/>
			</div>
		);
	}
}

export default translate("form")(Input);
