import React, { Component } from "react";
import classNames from "classnames";
import MenuItem from "./MenuItem";
import { translate } from "react-translate";
import { UISref } from "@uirouter/react";

class Menu extends Component {
	render() {
		let { lang, visible, t } = this.props;
		let container = classNames("menu", this.props.size, {
			open: visible
		});
		return (
			<div className={container}>
				<div className="scroll-menu">
					<MenuItem
						onClose={this.props.onClose}
						onClick={this.props.toogle}
						params={{ lang }}
						routeName="layout.homelang"
						name="about"
					/>
					<MenuItem
						onClose={this.props.onClose}
						onClick={this.props.toogle}
						params={{ lang }}
						routeName="layout.sumate"
						name="add"
					/>
					<MenuItem
						onClose={this.props.onClose}
						onClick={this.props.toogle}
						params={{ lang }}
						routeName="layout.donwload"
						name="donwload"
					/>
					<MenuItem
						onClose={this.props.onClose}
						onClick={this.props.toogle}
						params={{ lang }}
						routeName="layout.prensa"
						name="prensa"
					/>
					<MenuItem
						onClose={this.props.onClose}
						onClick={this.props.toogle}
						params={{ lang }}
						routeName="layout.colaboradores"
						name="colaboradores"
					/>

					<MenuItem
						onClose={this.props.onClose}
						onClick={this.props.toogle}
						params={{ lang }}
						routeName="layout.contact"
						name="contact"
					/>
					 
					<a
						href={ lang === 'es' ? "/avisodeprivacidad" : '/en/avisodeprivacidad'}
						target="new"
						className="privacy text-center">{t("aviso")}</a>
					 
					<div className="logos gray">
						<a href="http://www.sondemiel.com/" key={5} target="_new">
							<img src="/images/sondemiel.svg" />
						</a>

						<a href="https://www.prxlab.com/" target="_new">
							<img src="/images/prxlab.svg" key={6} />
						</a>

						<a href="http://www.arbol.mx/" key={7} target="_new">
							<img src="/images/arbol.svg" />
						</a>
						<a href="https://flock.mx/contacto/" key={8} target="_new">
							<img src="/images/flock.svg" />
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default translate("menu")(Menu);
