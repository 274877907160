import React, { Component } from "react";
import { UISref } from "@uirouter/react";
class Href extends Component {
	render() {
		let { routeName, params } = this.props;
		return (
			<UISref to={routeName} params={params}>
				<a>{this.props.children}</a>
			</UISref>
		);
	}
}

export default Href;
