import React, { Component } from "react";

import { translate } from "react-translate";

class Colaboradores extends Component {
	componentWillMount() {
		document.body.className = "colaboradores";
	}

	render() {
		let { lang, t } = this.props;
		return (
			<div className="container">
				<div className="column" />
				<div className="column">
					<h1 className="text-center">{t("title")}</h1>
					<p
						style={{ maxWidth: "748px" }}
						className="text-center description"
						dangerouslySetInnerHTML={{ __html: t("description") }}
					/>
					<div
						className="logos colaboradores"
						style={{ marginTop: 50 }}
					>
						<a href="http://www.sondemiel.com/" key={1} target="_new">
							<img src="/images/sondemiel.svg" />
						</a>

						<a href="https://www.prxlab.com/" target="_new">
							<img src="/images/prxlab.svg"  key={2}/>
						</a>

						<a href="http://www.arbol.mx/" key={3} target="_new">
							<img src="/images/arbol.svg" />
						</a>
						<a href="https://flock.mx/contacto/" key={4} target="_new">
							<img src="/images/flock.svg" />
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default translate("colaboradores")(Colaboradores);
