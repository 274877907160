import React, { Component } from "react";

class AvisoEn extends Component {

	componentWillMount() {
		document.body.className = "aviso";
	}

	render() {
		return (
			<div class="aviso-container">
				<p>
					By entering this website (the "Site"), it implies your acceptance of the Privacy Policy terms of the and you acquire the character of "User".
				</p>
				<p>
					Within the framework of the Federal Data protection regulation held by Individuals (the "Law"), the Federal Data protection regulation held by Private Parties (the "Regulations") and the Guidelines of the Privacy policy published by the Ministry of Economy in the Official Journal of the Federation (the "Guidelines") we request you as User (this term is defined below), to read the privacy notice that is detailed below:
				</p>
				<h3> Privacy Policy Agreement </h3>
				<h4>GENERAL INFORMATION</h4>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Last update</b>
						</td>
						<td valign="top">
							<p>April 4th, 2019</p>
						</td>
					</tr>
					<tr>
						<td valign="top">
							<b>
								Owner of Personal Data (the "User")
							</b>
						</td>
						<td valign="top">
							<p>
								Individual who can be identified directly or indirectly through any information provided, (i) directly, (ii) indirectly or (iii) in person, to Flock Advertising México, S.A.P.I. of C. V.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">
							<b>Personal Data</b>
						</td>
						<td valign="top">
							<p>
								Any information concerning the User, expressed in numerical, alphabetic, graphic, photographic, acoustic or any other type.
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<b>Responsible of the User Data</b>
						</td>
						<td valign="top">
							<p>
								Flock Advertising México, S.A.P.I. de C. V. (the "Company") is responsible for the use and protection of the Personal Data of the User.
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<b>Responsible address</b>
						</td>
						<td valign="top">
							<p>
								Vicente Suárez number 13, Colonia Condesa, Delegación Cuauhtémoc, Postal Code:  06140, in Mexico City.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">
							<b>Treatment</b>
						</td>
						<td valign="top">
							<p>
								The Company is responsible for obtaining and/or collecting the Personal Data of the User, as well as the use, protection, storage or disclosure that is given to them.

							</p>
							<p>
								The Personal Data of the User will be collected, stored, processed, organized, analyzed and transferred by the Company in the terms of this notice.
							</p>
						</td>
					</tr>
				</table>
				<h4> PURPOSES AND USES OF PERSONAL DATA</h4>
				<p>
					The Company will use the Personal Data of the User for the following purposes that are not necessary for the service and/or product requested, but that allow and facilitate the Company to provide a better service:
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Secondary purposes </b>
						</td>
						<td valign="top">
							<p>
								(i) Answer doubts and comment of the User.
							</p>
							<p>
								(i) You may express within five (5) business days following the receipt of this privacy notice, your refusal to process your personal data for secondary purposes, by means of the Application (term that is defined below)
							</p>
						</td>
					</tr>
				</table>
				<h4>PERSONAL DATA COLLECTION</h4>
				<p>
					To carry out Secondary Purposes, the Company will obtain and//or collect Personal Identification Data.	
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Personal Identification Data</b>
						</td>
						<td valign="top">
							<p>
								Full name, email address, telephone numbers, for which, the User grants the Company its express consent for its Treatment.
							</p>
						</td>
					</tr>
				</table>
				<h4>DATA COLLECTION</h4>
				<p>
					The Personal Data of the User may be collected and/ or obtained either in written, verbal or graphic form, by electronic, telephone or electromagnetic means	
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Cookies y WebBeacons</b>
						</td>
						<td valign="top">
							<p>
								The Site uses Cookies, WebBeacons and other technologies through it is possible to (i) monitor the behavior of the User as a User of the Site, (ii) provide the User a better service and experience when navigating the Site, (iii) as well as offer new products and services based on the User's preferences.
							</p>
							<p>
								The Personal Data that the Company obtains from these tracking technologies are the following: browsing time, navigation time on the Site, consulted sections and Internet pages previously accessed.
							</p>
							<p>
								On the other hand, the information contained on the hard disk can never be extracted through a cookie. Cookies usually have a temporary duration and, where appropriate, the User can configure his browser to accept or not the cookies he receives and/or notify him of their reception.
							</p>
							<p>
								To disable Cookies and WebBeacons, you must follow the following instructions:
							</p>
							<ul>
								<li>
									On a PC: open the Internet browser, enter the "Tools" menu, enter "Internet options", choose the "Privacy" tab, move the configuration to "Block all cookies”.
								</li>
								<li>
									On a Mac: open the internet application, go to "Preferences", select the option "Security", then select “Never” in the option "Accept cookies".
								</li>
							</ul>
						</td>
					</tr>
				</table>
				<h4>DATA SECURITY</h4>
				<p>
					The Company will implement the necessary security measures to ensure the protection of your personal data to prevent damage, loss, alteration, destruction or use, unauthorized access or treatment, in accordance with the established by the Law, the Regulations and the Guidelines.	
				</p>
				<p>
					Only designated and authorized persons by the Company may participate in the treatment of their personal data. This staff is prohibited from giving access to unauthorized persons and use their personal data for purposes other than those established and accepted in this notice.
				</p>
				<h4>CONSERVATION TERM OF THE PERSONAL DATA</h4>
				<p>
					Once the Secondary Purposes or the Purpose of the Treatment has been fulfilled, and when there is no legal or regulatory provision that establishes otherwise, the Company will proceed to cancel the Data in its possession after blocking them, for its subsequent deletion, under the terms established by the Law.
				</p>
				<h4>TRANSMISSION OF PERSONAL DATA</h4>
				<p>
					The Personal Data of the User can be transferred and treated with subsidiaries and other companies of the same commercial group, customers and suppliers of the Company.	
				</p>
				<p>
					If you do not express your opposition for the Personal Data to be transferred, it will be understood that upon accepting this Privacy Notice the User has granted the Company its express consent for its transmission.
				</p>
				<h4>PRIVACY POLICY CHANGES</h4>
				<p>
					This privacy notice may suffer modifications, changes or updates derived from new legal requirements; of the Company's own needs; or for other reasons
				</p>
				<p>
					The Company agrees to keep the User informed of any changes that may occur to this privacy notice through the site.
				</p>
				<p>
					The procedure about changes or updates to this privacy notice will be carried out by means of a notice at the moment when the User has access to the Site.
				</p>
				<h4>RIGHTS OF THE USER REGARDING PERSONAL DATA</h4>
				<p>
					The User may revoke the consent that, if applicable, he granted the Company the Treatment of Personal Data. However, it is important that the User takes into account that in all cases the Company will not be able to respond to your Request (this term is defined below) or to conclude the use immediately, since it is possible that due to some legal obligation the Company requires to continue treating the Personal Data of the User.
				</p>
				<p>
					The User of the Personal Data, by means of a request sent to the <a href="mailto:fmantilla@flock.mx">fmantilla@flock.mx</a> and <a href="mailto:rtovar@flock.mx">rtovar@flock.mx</a> or to the address of the Company (the "Application") may:
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">(i)</td>
						<td valign="top">
							<p>
								Manifest your refusal to process your personal data for the Secondary Purposes (as the term is defined below).
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(ii)</td>
						<td valign="top">
							<p>
								Exercise your right to access, rectify and cancel your personal data, as well as to oppose the treatment of the same ("ARCO Rights").
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iii)</td>
						<td valign="top">
							<p>
								Limit the use of your data ("Right of Limitation").
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iv)</td>
						<td valign="top">
							<p>
								Revoke the consent to the treatment of data for the purposes granted ("Right of Revocation").
							</p>
						</td>
					</tr>
				</table>
				<p>The Request must contain and accompany the following:</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">(i)</td>
						<td valign="top">
							<p>
								The full name of the User and his address or any other means by which he wishes to receive notifications regarding the Request.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(ii)</td>
						<td valign="top">
							<p>
								The documents that prove your identity as User, through a copy of your official identification document, having displayed the original for your comparison.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iii)</td>
						<td valign="top">
							<p>
								The clear and precise description of the personal data of the User with respect to those who seek to exercise any of the rights established in subsection a).
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iv)</td>
						<td valign="top">
							<p>
								Any element or document that facilitates the location of the User's personal data.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(v)</td>
						<td valign="top">
							<p>
								In the specific case of request for rectification of personal data, the User must indicate, in addition to what is indicated in the preceding paragraphs, the modifications to be made and provide the documentation supporting his request.
							</p>
						</td>
					</tr>
				</table>
				<p>
					The Company will respond through the means indicated by the User in the Request, within 20 (twenty) business days following its receipt.
				</p>
				<p>
					The Company may keep the personal data of the User exclusively for the purposes of the responsibilities arising from the process.
				</p>
				<h4>DOUBTS AND ADDITIONAL INFORMATION</h4>
				<p>
					If you have any questions about this privacy notice or any matter related to the treatment of your data in the terms hereof or how to exercise your ARCO, Limitation and Revocation Rights, you can contact the Privacy Department at the following <a href="mailto:fmantilla@flock.mx">fmantilla@flock.mx</a> y <a href="mailto:rtovar@flock.mx">rtovar@flock.mx</a>
				</p>
				<h4>ACCEPTANCE OF THE PRIVACY NOTICE</h4>
				<p>
					I declare that I have read and understand this notice and I grant my Consent (as the term defined by the Law) for the Treatment of my Personal Identification Data, for the Secondary Purposes, as well as for the transfer of them, under the terms of the present.
				</p>
				<p>
					Likewise, I manifest that by entering the Site and using it, I express my agreement with this privacy notice. I declare that my personal data is accurate, authentic and complete and I acknowledge that I am solely responsible for the accuracy, veracity and authenticity of my personal data.By entering the Site and using it, you signify your acceptance of this privacy policy.
				</p>
				<p>
					If you consider that your right to the protection of your personal data has been damaged by any conduct or omission of the Company, or presumes any violation of the dispositions of the Federal Law on Protection of Personal Data Held by Private Parties, its Regulations and other applicable ordinances, may file your disagreement or complaint with the National Institute of Access to Information and Data Protection (INAI). For more information, we suggest you visit their official website.
				</p>
			</div>
		);
	}
}

export default AvisoEn;
