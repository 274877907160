import React, { Component } from "react";

class Aviso extends Component {
	componentWillMount() {
		document.body.className = "aviso";
	}
	render() {
		return (
			<div class="aviso-container">
				<p>
					Al ingresar a ésta página de internet (el “Sitio”), implica
					su aceptación a los términos del Aviso de Privacidad y
					adquiere el carácter de “Titular”.
				</p>
				<p>
					En el marco de la Ley Federal de Protección de Datos
					Personales en Posesión de los Particulares (la “Ley”), el
					Reglamento de la Ley Federal de Protección de Datos
					Personales en Posesión de los Particulares (el “Reglamento”)
					y los Lineamientos del Aviso de Privacidad publicados por la
					Secretaría de Economía en el Diario Oficial de la Federación
					(los “Lineamientos”) le solicitamos como Titular (dicho
					término se define más adelante), leer el aviso de privacidad
					que a continuación se detalla:
				</p>
				<h3>Aviso de Privacidad Integral</h3>
				<h4>INFORMACIÓN GENERAL</h4>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Fecha de última actualización</b>
						</td>
						<td valign="top">
							<p>04 de abril de 2019</p>
						</td>
					</tr>
					<tr>
						<td valign="top">
							<b>
								Titular de los Datos Personales (el “Titular”)
							</b>
						</td>
						<td valign="top">
							<p>
								Persona física que puede ser identificada
								directa o indirectamente mediante cualquier
								información proporcionada, (i) directamente,
								(ii) indirectamente o (iii) personalmente, a
								Flock Advertising México, S.A.P.I. de C. V.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">
							<b>Datos Personales</b>
						</td>
						<td valign="top">
							<p>
								Cualquier información concerniente al Titular,
								expresada en forma numérica, alfabética,
								gráfica, fotográfica, acústica o en cualquier
								otro tipo.
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<b>Responsable de los Datos del Titular</b>
						</td>
						<td valign="top">
							<p>
								Flock Advertising México, S.A.P.I. de C. V. (la
								“Empresa”) es el responsable del uso y
								protección de los Datos Personales del Titular.
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<b>Domicilio del Responsable</b>
						</td>
						<td valign="top">
							<p>
								Vicente Suárez número 13, Colonia Condesa,
								Delegación Cuauhtémoc, Código Postal 06140, en
								la Ciudad de México
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">
							<b>Tratamiento</b>
						</td>
						<td valign="top">
							<p>
								La Empresa es responsable de obtener y/o recabar
								los Datos Personales del Titular, así como del
								uso, protección, almacenamiento o divulgación
								que se le dé a los mismos. Los Datos Personales
								del Titular serán recabados, almacenados,
								procesados, organizados, analizados y
								transferidos por la Empresa en los términos del
								presente aviso.
							</p>
						</td>
					</tr>
				</table>
				<h4>FINALIDADES Y USOS DE LOS DATOS PERSONALES</h4>
				<p>
					La Empresa utilizará los Datos Personales del Titular para
					las siguientes finalidades que no son necesarias para el
					servicio y/o producto solicitado, pero que permiten y
					facilitan a la Empresa brindarle una mejor atención:
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Finalidades Secundarias</b>
						</td>
						<td valign="top">
							<p>
								(i) Dar respuesta a dudas y comentarios del
								Titular.
							</p>
							<p>
								Usted puede manifestar dentro de los 5 (cinco)
								días hábiles siguientes a la recepción del
								presente aviso de privacidad, su negativa para
								el Tratamiento de sus datos personales con
								finalidad secundaria, mediante la Solicitud
								(término que se define más adelante)
							</p>
						</td>
					</tr>
				</table>
				<h4>DATOS PERSONALES QUE SE RECABAN</h4>
				<p>
					Para llevar a cabo las Finalidades Secundarias, la Empresa
					obtendrá y/o recabará Datos Personales de Identificación
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Datos Personales de Identificación</b>
						</td>
						<td valign="top">
							<p>
								Nombre completo, dirección de correo
								electrónico, números telefónicos, para los
								cuales, el Titular le otorga a la Empresa su
								consentimiento expreso para su Tratamiento.
							</p>
						</td>
					</tr>
				</table>
				<h4>OBTENCIÓN DE DATOS</h4>
				<p>
					Los Datos Personales del Titular podrán ser recabados y/o
					obtenidos ya sea de manera escrita, verbal o gráfica, por
					medios electrónicos, telefónicos o electromagnéticos
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">
							<b>Cookies y WebBeacons</b>
						</td>
						<td valign="top">
							<p>
								El Sitio utiliza Cookies, WebBeacons y otras
								tecnologías a través de las cuales es posible
								(i) monitorear el comportamiento del Titular
								como usuario del Sitio, (ii) brindarle al
								Titular un mejor servicio y experiencia de
								usuario al navegar en el Sitio, (iii) así como
								ofrecerle nuevos productos y servicios basados
								en las preferencias del Titular. Los Datos
								Personales que la Empresa obtiene de estas
								tecnologías de rastreo son los siguientes:
								horario de navegación, tiempo de navegación en
								el Sitio, secciones consultadas, y páginas de
								Internet accedidas previamente. Por otro lado,
								nunca a través de una Cookie se puede extraer la
								información contenida en el disco duro. Las
								Cookies suelen tener una duración temporal y, en
								su caso, el Titular puede configurar su
								navegador para aceptar o no las cookies que
								recibe y/o avisarle de su recepción.
							</p>
							<p>
								Para desactivar Cookies y WebBeacons, debe
								seguir las siguientes instrucciones:
							</p>
							<ul>
								<li>
									En una PC: abrir el explorador de internet,
									entrar al menú de “Herramientas”, entrar a
									“Opciones de internet”, escoger la pestaña
									de “Privacidad”, mover el cursor de la
									configuración hacia “Bloquear todas los
									cookie.
								</li>
								<li>
									En una Mac: abrir la aplicación de internet,
									ir a “Preferencias”, escoger la opción de
									“Seguridad”, escoger “Nunca” en la opción de
									“Aceptar cookies”.
								</li>
							</ul>
						</td>
					</tr>
				</table>
				<h4>SEGURIDAD DE LOS DATOS</h4>
				<p>
					La Empresa implementará las medidas de seguridad necesarias
					para procurar la protección de sus datos personales para
					evitar su daño, pérdida, alteración, destrucción o el uso,
					acceso o tratamiento no autorizado, de conformidad con lo
					establecido en la Ley, el Reglamento y los Lineamientos.
				</p>
				<p>
					Únicamente el personal designado y autorizado por la Empresa
					podrá participar en el Tratamiento de sus datos personales.
					Dicho personal tiene prohibido dar acceso a personas no
					autorizadas y utilizar sus datos personales para fines
					distintos a los establecidos y aceptados en el presente
					aviso.
				</p>
				<h4>PLAZO DE CONSERVACIÓN DE LOS DATOS PERSONALES</h4>
				<p>
					Una vez cumplida la o las Finalidades Secundarias del
					Tratamiento, y cuando no exista disposición legal o
					reglamentaria que establezca lo contrario, la Empresa
					procederá a la cancelación de los Datos en su posesión
					previo bloqueo de los mismos, para su posterior supresión,
					en los términos establecidos en la Ley.
				</p>
				<h4>TRANSMISIÓN DE LOS DATOS PERSONALES</h4>
				<p>
					Los Datos Personales del Titular pueden ser transferidos y
					tratados con sociedades filiales y demás sociedades del
					mismo grupo comercial, clientes y proveedores de la Empresa.
				</p>
				<p>
					Si no manifiesta su oposición para que los Datos Personales
					sean transferidos, se entenderá que a la firma del presente
					Aviso de Privacidad el Titular ha otorgado a la Empresa su
					consentimiento expreso para su transmisión.
				</p>
				<h4>CAMBIOS AL AVISO DE PRIVACIDAD</h4>
				<p>
					El presente aviso de privacidad puede sufrir modificaciones,
					cambios o actualizaciones derivadas de nuevos requerimientos
					legales; de propias necesidades de la Empresa; o por otras
					causas.
				</p>
				<p>
					La Empresa se compromete a mantener al Titular informado
					sobre los cambios que pueda sufrir el presente aviso de
					privacidad, a través del Sitio.
				</p>
				<p>
					El procedimiento a través del cual se llevarán a cabo las
					notificaciones sobre cambios o actualizaciones al presente
					aviso de privacidad es mediante un aviso al momento que el
					Titular tenga acceso al Sitio
				</p>
				<h4>DERECHOS DEL TITULAR RESPECTO A LOS DATOS PERSONALES</h4>
				<p>
					El Titular puede revocar el consentimiento que, en su caso,
					haya otorgado a la Empresa para el Tratamiento de sus Datos
					Personales. Sin embargo, es importante que el Titular tenga
					en cuenta que no en todos los casos la Empresa podrá atender
					su Solicitud (dicho término se define más adelante) o
					concluir el uso de forma inmediata, ya que es posible que
					por alguna obligación legal la Empresa requiera seguir
					tratando los Datos Personales del Titular.
				</p>
				<p>
					El Titular de los Datos Personales, mediante solicitud
					enviada a los correos electrónicos{" "}
					<a href="mailto:fmantilla@flock.mx">fmantilla@flock.mx</a> y{" "}
					<a href="mailto:rtovar@flock.mx">rtovar@flock.mx</a> o al
					domicilio de la Empresa (la “Solicitud”) podrá:
				</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">(i)</td>
						<td valign="top">
							<p>
								Manifestar su negativa para el tratamiento de
								sus datos personales para las Finalidades
								Secundarias (como dicho término se define más
								adelante).
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(ii)</td>
						<td valign="top">
							<p>
								Ejercer su derecho de acceder, rectificar y
								cancelar sus datos personales, así como el de
								oponerse al Tratamiento de los mismos (“Derechos
								ARCO“).
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iii)</td>
						<td valign="top">
							<p>
								Limitar el uso de sus datos (“Derecho de
								Limitación”).{" "}
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iv)</td>
						<td valign="top">
							<p>
								Revocar el consentimiento al Tratamiento de los
								datos que para los fines referidos se haya
								otorgado (“Derecho de Revocación”).
							</p>
						</td>
					</tr>
				</table>
				<p>La Solicitud deberá contener y acompañar lo siguiente:</p>
				<table width="100%" border={0}>
					<tr>
						<td valign="top">(i)</td>
						<td valign="top">
							<p>
								El nombre completo del Titular y su domicilio o
								cualquier otro medio por el cual desee recibir
								notificaciones respecto de la Solicitud.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(ii)</td>
						<td valign="top">
							<p>
								Los documentos que acrediten su identidad como
								Titular, a través de la presentación de copia de
								su documento de identificación oficial, habiendo
								exhibido el original para su cotejo.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iii)</td>
						<td valign="top">
							<p>
								La descripción clara y precisa de los datos
								personales del Titular respecto de los que busca
								ejercer cualquiera de los derechos establecidos
								en el inciso a).
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(iv)</td>
						<td valign="top">
							<p>
								Cualquier elemento o documento que facilite la
								localización de los datos personales del
								Titular.
							</p>
						</td>
					</tr>
					<tr>
						<td valign="top">(v)</td>
						<td valign="top">
							<p>
								En el caso específico de solicitud de
								rectificación de datos personales, el Titular
								deberá indicar, además de lo señalado en los
								incisos anteriores, las modificaciones a
								realizarse y aportar la documentación que
								sustente su petición.
							</p>
						</td>
					</tr>
				</table>
				<p>
					La Empresa responderá a través del medio señalado por el
					Titular en la Solicitud, dentro de los 20 (veinte) días
					hábiles siguientes a su recepción.
				</p>
				<p>
					La Empresa podrá conservar los datos personales del Titular
					exclusivamente para efectos de las responsabilidades nacidas
					del Tratamiento.
				</p>
				<h4>DUDAS E INFORMACIÓN ADICIONAL</h4>
				<p>
					Si tiene alguna pregunta acerca de este aviso de privacidad
					o cualquier asunto relacionado con el Tratamiento de sus
					datos en los términos del presente o de cómo ejercer sus
					Derechos ARCO, de Limitación y Revocación, puede contactar
					al departamento de Privacidad a los siguientes correos
					electrónicos{" "}
					<a href="mailto:fmantilla@flock.mx">fmantilla@flock.mx</a> y{" "}
					<a href="mailto:rtovar@flock.mx">rtovar@flock.mx</a>{" "}
				</p>
				<h4>ACEPTACIÓN DEL AVISO DE PRIVACIDAD</h4>
				<p>
					Manifiesto que he leído y entiendo el presente aviso y
					otorgo mi Consentimiento (como dicho término se define en la
					Ley) para el Tratamiento de mis Datos Personales de
					Identificación, para las Finalidades Secundarias, así como
					para la transferencia de los mismos, en los términos del
					presente.
				</p>
				<p>
					Asimismo, manifiesto que al entrar al Sitio y usarlo
					manifiesto mi conformidad con el presente aviso de
					privacidad.
				</p>
				<p>
					Manifiesto que mis datos personales son exactos, auténticos
					y completos y por lo tanto reconozco que soy el único
					responsable de la exactitud, veracidad y autenticidad de mis
					datos personales
				</p>
				<p>
					Al ingresar al Sitio y usarlo, usted da su conformidad con
					el presente aviso de privacidad.
				</p>
				<p>
					Si usted considera que su derecho a la protección de sus
					datos personales ha sido lesionado por alguna conducta u
					omisión de la Empresa, o presume alguna violación a las
					disposiciones previstas en la Ley Federal de Protección de
					Datos Personales en Posesión de los Particulares, su
					Reglamento y demás ordenamientos aplicables, podrá
					interponer su inconformidad o denuncia ante el Instituto
					Nacional de Acceso a la Información y Protección de Datos
					(INAI). Para mayor información, le sugerimos visitar su
					página oficial de Internet.
				</p>
			</div>
		);
	}
}

export default Aviso;
