import React, { Component } from "react";
import { UIView } from "@uirouter/react";
import { TranslatorProvider } from "react-translate";
import Header from "../components/Header";

class Layout extends Component {
	render() {
		let { $stateParams } = this.props;
		let lang = $stateParams.lang || "es";
		return (
			<TranslatorProvider translations={require(`../i18n/${lang}.json`)}>
				<div className="wrapper wrapper-home">
					<Header {...this.props} lang={lang} />
					<UIView
						render={(Component, props) => (
							<Component
								routeName={props.$state$.name}
								{...props}
								lang={lang}
							/>
						)}
					/>
				</div>
			</TranslatorProvider>
		);
	}
}

export default Layout;
