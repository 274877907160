import React, { Component } from "react";
import ButtonHexagonal from "../components/ButtonHexagonal";
import { translate } from "react-translate";

class Donwload extends Component {
	componentWillMount() {
		document.body.className = "donwload";
	}

	donwload(){
		window.open('/PLANOS_LAST_STRAW.pdf', "_new");
	}

	render() {
		let { lang, t } = this.props;
		return (
			<div className="container">
				<div className="column" />
				<div className="column">
					<div className="column-content">
						<h1
							className="text-center"
							dangerouslySetInnerHTML={{ __html: t("title") }}
						/>
						<p
							className="text-center description"
							dangerouslySetInnerHTML={{
								__html: t("description")
							}}
						/>

						<img
							src="/images/descargar.jpg"
							className="responsive-image"
							style={{ marginBottom: 30 }}
						/>

						<ButtonHexagonal name="donwload-pdf" onClick={this.donwload.bind(this)} />
					</div>
				</div>
			</div>
		);
	}
}

export default translate("donwload")(Donwload);
