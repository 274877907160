import React, { Component } from "react";
import { translate } from "react-translate";
import Input from "../components/form/Input";
import TextArea from "../components/form/TextArea";
import axios from "axios";
import { validEmail } from "../config/Utils";
import ModalThanks from "../components/ModalThanks";

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			phone: "",
			email: "",
			comments: "",
			loading: false,
			visible: false
		};
	}

	componentWillMount() {
		document.body.className = "contact";
	}

	_send() {
		this.setState({ loading: true });
		let { name, phone, email, comments } = this.state;
		const formData = new FormData();
		formData.append("FORM", "formulario de contacto");
		formData.append("NAME", name);
		formData.append("PHONE", phone);
		formData.append("EMAIL", email);
		formData.append("MESSAGE", comments);
		formData.append("LANG", this.props.lang);
		axios
			.post("http://laststraw.flockos.com/email/index.php", formData, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				}
			})
			.then(response => {
				this.setState({
					email: "",
					name: "",
					phone: "",
					comments: "",
					loading: false,
					visible: true
				});
			})
			.catch(err => {
				this.setState({ loading: false });
			});
	}

	_getDisabled() {
		let disabled = false;
		if (!validEmail(this.state.email)) disabled = true;
		if (!this.state.name || this.state.name.length <= 3) disabled = true;
		if (!this.state.phone || this.state.phone.length < 8) disabled = true;
		if (!this.state.comments || this.state.comments.length <= 5)
			disabled = true;
		return disabled;
	}

	render() {
		let { lang, t } = this.props;
		return (
			<div className="container">
				<div className="column" />
				<div className="column">
					<div className="column-content">
						<h1 className="text-center">{t("title")}</h1>
						<p
							className="text-center description"
							dangerouslySetInnerHTML={{
								__html: t("description")
							}}
						/>

						<p style={{ width: "100%", letterSpacing: "0.1rem" }}>
							<b>{t("form")}</b>
						</p>
						<Input
							value={this.state.name}
							onChange={name => this.setState({ name })}
							name="name"
						/>
						<Input
							maxLength={13}
							value={this.state.phone}
							onChange={phone =>
								this.setState({
									phone: phone.replace(/([a-zA-Z])+/gi, "")
								})
							}
							type="tel"
							name="phone"
						/>
						<Input
							value={this.state.email}
							onChange={email => this.setState({ email })}
							type="email"
							name="email"
						/>
						<TextArea
							value={this.state.comments}
							onChange={comments => this.setState({ comments })}
							type="comments"
							name="comments"
						/>
						<button
							style={{ marginLeft: "auto" }}
							disabled={this._getDisabled()}
							className="send"
							onClick={this._send.bind(this)}
						>
							{t(this.state.loading ? "loading" : "send")}
						</button>
					</div>
				</div>
				<ModalThanks
					visible={this.state.visible}
					onClose={() =>
						this.setState({ visible: !this.state.visible })
					}
				/>
			</div>
		);
	}
}

export default translate("contact")(Contact);
