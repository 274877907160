import React, { Component } from "react";
import { translate } from "react-translate";
class ButtonHexagonal extends Component {
	_onClick() {
		if (this.props.onClick) this.props.onClick();
	}

	render() {
		let { t, name, size } = this.props;
		return (
			<div
				className={`button hexagonal ${size}`}
				onClick={this._onClick.bind(this)}
			>
				<div className="button-available">{t(name)}</div>
			</div>
		);
	}
}

export default translate("button")(ButtonHexagonal);
